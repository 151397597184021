<template>
  <div class="contaner_w">
    <div class="header_tab">
      <new-header :config="headerConfig"></new-header>
    </div>

    <div class="header_top_box">
      <img src="../../assets/Mask_group@2x.png" />
      <div class="piont_box">
        <div class="piont_box_t">当前贡献值</div>
        <div class="piont_box_s">{{ userFrom.member.contribution_balance }}</div>
      </div>
    </div>

    <div class="progress_list">
      <div
        class="progress_content"
        v-for="(item, index) in contribution.tables"
        :key="index"
      >
        <div>{{ item.name }}</div>
        <div class="progress_box">
          <div
            :style="`width:${item.proportion.split('%')[0]}%;`"
            :class="
              item.module_type == 3
                ? 'proportion1'
                : item.module_type == 2
                ? 'proportion3'
                : 'proportion2'
            "
          >
            <div class="left_price">{{ item.contribution_amount }}</div>
            <div class="right_rate">{{ item.proportion }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 兑换方式 -->
    <div class="replacement_content">
      <div class="distribution">兑换方式</div>
      <div>
        <van-radio-group
          v-model="radio"
          @change="changeRadio"
          direction="horizontal"
          class="radio_text"
        >
          <van-radio name="1" shape="square">默认方式</van-radio>
          <van-radio style="margin-left: 30px" name="2" shape="square"
            >指定门店/实体店</van-radio
          >
        </van-radio-group>
      </div>
      <div class="error_text" v-if="radio == 1">
        *全选时按板块选择顺序消耗每个板块的贡献值
      </div>
      <div class="btn_list" v-if="radio == 1">
        <div
          @click="typeBtn(1)"
          :class="selectModel.includes(1) ? 'btn0 selectBgc' : 'btn0'"
        >
          购购平台
          <div v-if="selectModel.indexOf(1) != -1" class="bar">
            {{ selectModel.indexOf(1) + 1 }}
          </div>
        </div>
        <div
          @click="typeBtn(3)"
          :class="selectModel.includes(3) ? 'btn1 selectBgc' : 'btn1'"
        >
          氏客门店
          <div v-if="selectModel.indexOf(3) != -1" class="bar">
            {{ selectModel.indexOf(3) + 1 }}
          </div>
        </div>
        <div
          @click="typeBtn(2)"
          :class="selectModel.includes(2) ? 'btn2 selectBgc' : 'btn2'"
        >
          企业实体
          <div v-if="selectModel.indexOf(2) != -1" class="bar">
            {{ selectModel.indexOf(2) + 1 }}
          </div>
        </div>
      </div>

      <div class="distribution" style="margin: 40px 0 30px 0">
        可用贡献值：<span>{{ contribution_balance }}</span
        ><span class="order" v-if="radio == 2" @click="selectBtn"
          >请选择门店/或企业 ></span
        >
      </div>

      <div class="title1">请输入兑换工分</div>
      <input
        class="input_box plass_text"
        v-model="shareDate"
        @input="changeBtn"
        :disabled="contribution_balance == 0"
        :placeholder="
          contribution_balance == 0 ? '请先选择可用贡献值' : '请输入需兑换的工分'
        "
      />
      <div v-if="shareDatePiont" class="error_text">*消耗{{ shareDatePiont }}贡献值</div>
      <van-loading v-if="loadingShow" type="spinner" color="#1989fa" />
      <div class="submit_btn" @click="tabBtn">确认兑换</div>
    </div>

    <!-- 提示框 -->
    <van-dialog v-model="show" :show-confirm-button="false">
      <div class="cancel_box">
        <img src="https://cdn.health.healthplatform.xyz/Fjmn3KOW5Zax17HdrGyhlwktZQOw" />
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="message_text">
            尊敬的会员您好，激活企业工分后将自动签署销售合约，合约以电子文件形式保留，后续您可将贡献值兑换获取企业工分。
          </div>
          <div class="sure_btn" @click="sureBtn">我知道了</div>
          <div class="text1" @click="sureBtnUser" v-if="!userFrom.union_shareholder_info">
            不兑换，直接成为工分会员
          </div>
          <div class="rule_text">
            <span>《企业销售协议》</span>与<span>《企业工会会员分红赠予合同》</span>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import { Toast } from "vant";
import { getCode_api } from "@/api/common";
import Cookies from "js-cookie";
// import customradio from "../../components/custom/customradio";
import {
  getSetting,
  getuser_api,
  getContributionExchangeShare_api,
  updateExchangeShare_api,
} from "@/api/common";
export default {
  data() {
    return {
      headerConfig: {
          show: true,
          title: '工分置换',
      },
      show: false,
      shareDate: "",
      dataBtn: 1,
      contribution_balance: 0,
      bgc: "",
      radio: "1",
      selectModel: [], //选择专区
      shareDatePiont: "", //消耗贡献值
      loadingShow: false, //加载中
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      userFrom: {
        member: {},
      },
      settingDate: {},
      contribution: {},
      selectList: [],
    };
  },
  components: {
    newHeader,
    // customradio
  },
  watch() {},
  mounted() {
    if (localStorage.getItem("storeList")) {
      this.selectList = JSON.parse(localStorage.getItem("storeList"));
      if (this.selectList.length > 0) {
        this.radio = "2";
      } else {
        this.radio = "1";
      }
      this.contribution_balance = 0;
      this.selectList.forEach((e) => {
        this.contribution_balance =
          this.contribution_balance + Number(e.surplus_contribution_amount);
      });
    } else {
      this.contribution_balance = 0;
    }
  },
  created() {
    // app跳转
    if (this.$route.query.code) {
      this.getUserLogin();
      return false;
    }
    document.title = "";
    this.getData();
    
  },
  methods: {
    getData() {
      this.getUser();
      this.getConfig();
      this.getContribution();
      if (localStorage.getItem("storeList")) {
        this.selectList = JSON.parse(localStorage.getItem("storeList"));
        if (this.selectList.length > 0) {
          this.radio = "2";
        } else {
          this.radio = "1";
        }
        this.contribution_balance = 0;
        this.selectList.forEach((e) => {
          this.contribution_balance =
            this.contribution_balance + Number(e.surplus_contribution_amount);
        });
      } else {
        this.contribution_balance = 0;
      }
    },
    //获取用户信息
    async getUserLogin(code) {
      let data = {
        code: code,
      };
      getCode_api(data).then((res) => {
        console.log(res, 123123123);
        if (res.code == 0) {
          Cookies.set("token", res.data.access_token);
          this.getData();
        } else {
          Toast(res.error)
        }
      });
    },
    // 初始化选项
    changeRadio(e) {
      console.log(e);
      this.contribution_balance = 0;
      this.storeList = [];
      localStorage.removeItem("storeList");
    },
    typeBtn(index) {
      console.log(index);
      let indexs = this.selectModel.findIndex((e) => {
        return e == index;
      });
      if (indexs == -1) {
        this.selectModel.push(index);
      } else {
        this.selectModel.splice(indexs, 1);
      }

      this.contribution_balance = 0;
      this.selectModel.forEach((e) => {
        this.contribution.tables.forEach((i) => {
          if (e == 1) {
            if (i.contribution_type == 1)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 2) {
            if (i.contribution_type == 2)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 3) {
            if (i.contribution_type == 3)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
          if (e == 4) {
            if (i.contribution_type == 4)
              this.contribution_balance =
                this.contribution_balance + Number(i.contribution_amount);
          }
        });
      });
    },
    getUser() {
      getuser_api().then((res) => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          this.$forceUpdate();
        }
      });
    },
    getConfig() {
      getSetting().then((res) => {
        console.log(res.status);
        if (res.code == 0) {
          this.settingDate = res.data;
          this.$forceUpdate();
        }
      });
    },
    getContribution() {
      getContributionExchangeShare_api().then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.contribution = res.data;
        }
      });
    },

    //貢獻值結算
    changeBtn() {
      this.loadingShow = true;
      let data = {
        exchange_type: Number(this.radio),
        exchange_share: Number(this.shareDate),
        super_id: [],
        exchange_module: this.selectModel,
      };
      if (this.radio == 2) {
        this.selectList.forEach((e) => {
          data.super_id.push(e.super_id);
        });
      } else {
        delete data.super_id;
      }
      updateExchangeShare_api(data).then((res) => {
        if (res.code == 0) {
          this.loadingShow = false;
          this.shareDatePiont = res.data.convertible_contribution_amount;
        } else {
          Toast(res.error);
          this.loadingShow = false;
        }
      });
    },
    submitBtn() {
      this.show = true;
    },
    selectBtn() {
      this.$router.push(`../storeAssist/selectOrder`);
    },
    tabBtn() {
      if (!this.shareDate) {
        Toast("请输入兑换工分");
        return false;
      }
      this.show = true;
    },
    // 单独成为工分会员
    sureBtnUser() {
      let data = {
        exchange_type: Number(this.radio),
        exchange_share: Number(this.shareDate),
        super_id: [],
        exchange_module: this.selectModel,
      };
      if (this.radio == 2) {
        this.selectList.forEach((e) => {
          data.super_id.push(e.super_id);
        });
      } else {
        delete data.super_id;
      }
      localStorage.setItem("workData", JSON.stringify(data));

      this.$router.push(`./enterprise?type=3`);
    },
    // 数据提交
    sureBtn() {
      let data = {
        exchange_type: Number(this.radio),
        exchange_share: Number(this.shareDate),
        super_id: [],
        exchange_module: this.selectModel,
      };
      if (this.radio == 2) {
        this.selectList.forEach((e) => {
          data.super_id.push(e.super_id);
        });
      } else {
        delete data.super_id;
      }
      let time_s =
        new Date().getTime() + 2592000000 >
        new Date(this.userFrom.union_shareholder_expire_at).getTime();
      // 不是股东或者股东身份还有三十天到期
      if (!this.userFrom.union_shareholder_info || time_s) {
        this.$router.push(`./enterprise?type=1`);
      } else {
        this.$router.push(`./enterprise?type=2`);
      }
      localStorage.setItem("workData", JSON.stringify(data));
      localStorage.removeItem("shareDate");
      localStorage.removeItem("active");
    },
    cloneBtn() {
      this.show = false;
    },
  },
};
</script>
<style scoped lang="less">
.contaner_w {
  background-color: #fff;
}
.progress_list {
  border-bottom: 20px solid#F2F2F7;
  padding: 50px 30px 30px;
  box-sizing: border-box;
}
.progress_content {
  display: flex;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  color: #1a1a1a;
  margin-bottom: 40px;

  .progress_box {
    width: 550px;
    height: 50px;
    background: #f2f2f7;
    border-radius: 30px 30px 30px 30px;
    padding: 0;
    margin-left: 20px;
    .proportion1 {
      width: 409px;
      height: 50px;
      background: linear-gradient(90deg, #1b94ff 0%, #076aff 100%);
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      // padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .proportion2 {
      width: 550px;
      height: 50px;
      background: linear-gradient(90deg, #78cb75 0%, #4ab86f 100%);
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      // padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .proportion3 {
      width: 304px;
      height: 50px;
      background: linear-gradient(90deg, #ff9335 0%, #ff6a00 100%);
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      // padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
  }
}

.header_tab {
  width: 100%;
  position: absolute;
  top: 0;
}

.header_top_box {
  width: 100%;
  height: 380px;
}

.header_top_box img {
  height: 380px;
}

.piont_box {
  position: absolute;
  top: 216px;
  left: 53px;
}

.piont_box_t {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: rgba(26, 26, 26, 0.8);
}

.piont_box_s {
  font-family: DIN, DIN;
  font-weight: bold;
  font-size: 60px;
  color: #a65200;
}

.replacement_message {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.8);
}

.replacement_message div {
  line-height: 45px;
}

.replacement_content {
  padding: 20px 38px;
  box-sizing: border-box;
}

.distribution {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  margin: 30px 0 20px 0;
}

.distribution_list {
  display: flex;
  flex-wrap: wrap;
}

.list_img {
  width: 320px;
  height: 170px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #1a1a1a;
  background-size: 100% 100%;
  text-align: center;
  padding-top: 44px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.distribution_list .bgc1 {
  margin-right: 20px;
  background-image: url(https://cdn.health.healthplatform.xyz/FttIjBDgGbWaR8eqk6xxbnCfr3RF);
}

.distribution_list .bgc2 {
  background-image: url(https://cdn.health.healthplatform.xyz/Fu9RcpLv4J09txPMaNrCGEmDx6vs);
}

.distribution_list .bgc3 {
  margin-right: 20px;
  background-image: url(https://cdn.health.healthplatform.xyz/FvqLiw-xiT9b3MoC9I0Rh_jaC9GM);
}

.distribution_list .bgc4 {
  background-image: url(https://cdn.health.healthplatform.xyz/FtzO0AGiemFYiVMX_U6htMOuwATv);
}

.radio_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #1a1a1a;
  // margin: 37px 32px 0 87px;
  display: flex;
}

.error_text {
  width: 602px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #fe3b22;
  margin: 32px 0 30px 20px;
}

.btn_list {
  display: flex;

  .bar {
    width: 32px;
    height: 32px;
    background-color: red;
    text-align: center;
    line-height: 32px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: -16px;
    right: -30px;
  }
}

.btn_list div {
  width: 140px;
  height: 60px;
  position: relative;
  background: rgba(7, 139, 255, 0.1);
  border-radius: 4px 4px 4px 4px;
  /* border: 1px solid #078bff;/ */
  text-align: center;
  line-height: 60px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #078bff;
  margin-right: 20px;
}
.btn_list .btn0 {
  background: linear-gradient(90deg, #1b94ff 0%, #076aff 100%);
  color: #fff;
  /* border: 1px solid #ff461e; */
}
.btn_list .btn1 {
  background: rgba(74, 184, 111, 1);
  color: #fff;
  /* border: 1px solid #ff461e; */
}

.btn_list .btn2 {
  background: rgba(255, 106, 0);
  color: #fff;
  /* border: 1px solid #14bf84; */
}

.btn_list .btn3 {
  background: rgba(255, 106, 0);
  color: #fff;
  /* border: 1px solid #ff8001; */
}

.selectBgc {
  border: 1px solid #000;
}

.distribution span {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #fe3b22;
}

.distribution .order {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #078bff;
  margin-left: 25px;
}

.title1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
}

.input_box {
  width: 600px;
  height: 80px;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}

.plass_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  padding: 22px 0 22px 33px;
  box-sizing: border-box;
}

.submit_btn {
  width: 340px;
  height: 80px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  margin: 60px auto;
}

.cancel_box {
  width: 100%;
  height: 100%;
}

.cancel_box img {
  width: 100%;
  height: 100%;
}

.text_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.message_title {
  margin: 10px 0 50px;
}

.message_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: rgba(26, 26, 26, 0.8);
  width: 528px;
  margin: 31px auto 40px;
  text-align: left;
}

.sure_btn {
  width: 180px;
  height: 68px;
  background: linear-gradient(179deg, #ff6f51 0%, #ff0639 100%);
  border-radius: 34px 34px 34px 34px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
  margin: 36px auto 31px;
}

.text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 24px;
}

.rule_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #1a1a1a;
}
.rule_text span {
  color: #007dff;
}
.text_box img {
  width: 25px;
  height: 25px;
  margin-top: 52px;
  margin-left: 450px;
}
.progress_box {
  padding: 50px 30px;
  box-sizing: border-box;
  position: relative;
  .left_price {
    left: 20px;
    position: absolute;
    top: 3px;
  }
  .right_rate {
    right: 20px;
    top: 3px;
    position: absolute;
  }
}
</style>
